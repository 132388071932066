<template>
  <div class="interact-add">
    <MyBreadcrumb></MyBreadcrumb>
    <div class="interact-top">
      <div class="interact-top-l">
        <div class="interact-top-l-t">
          <span>设置标题：</span>
          <el-input v-model="name" size="small" placeholder="请输入标题,最长20个字" style="width: 470px" maxlength="20"></el-input>
        </div>
        <div class="interact-top-l-b">
          <p>提前设置课堂中需要互动的题目，快速使用教学</p>
<!--          <p class="interact-top-l-b-help">-->
<!--            <span>查看教程</span>-->
<!--            <img src="../../assets/images/new_icon/help.png" width="22" height="22" />-->
<!--          </p>-->
        </div>
      </div>
      <div class="interact-top-r">
        <el-button size="small" @click="cancel">取消</el-button>
        <el-button type="primary" size="small" @click="save" v-loading="saveLoading">发布</el-button>
      </div>
    </div>
    <div class="interact-cont">
      <el-row   style="width: 100%">
        <el-col :span="10" style="height: 100%">
          <div class="dataList-l">
            <div class="dataList-l-top">
              <p>已选题目</p>
              <p class="dataList-l-top-fgx">|</p>
              <p class="dataList-l-top-num">共计<span>{{selectDataList.length}}</span>题</p>
            </div>
            <div class="dataList-cont-list">
              <div class="dataList-cont-list-item" v-for="item in dataTypeList" :key="item.type">
                <div class="dataList-cont-list-item-tit"  @click="showItem(item)">
                  <p></p>
                  <div>{{ item.name }} ({{ item.list.length }}题)</div>
                  <img src="../../assets/images/new_icon/shang.png" width="10" height="8" :style="{transform:item.show ? 'rotate(0deg)' : 'rotate(180deg)'}" />
                </div>
                <div class="dataList-cont-list-item-div" style="overflow: hidden" :style="{height: item.show ? (item.list.length + 1) * 45 + 'px' : '0px'}">
                  <table class="dataList-cont-list-item-table">
                    <tr>
                      <th class="th-title">题目</th>
                      <th class="table-level">难度</th>
                      <th class="data-btns">操作</th>
                    </tr>
                    <tr v-for="sItem in item.list" :key="sItem.id">
                      <td class="data-tit" @click="showDetail(sItem)">
<!--                        <p class="data-type" :class="sItem.type == 1 ? 'bjFD0' : sItem.type == 2 ? 'purple' : 'bj3ff' ">{{ dataTypes[sItem.type] }}</p>-->
                        <div>{{ sItem.title }}</div>
                      </td>
                      <td class="table-level">{{ dataLevels[sItem.level] }}</td>
                      <td class="data-btns" >
                        <img src="../../assets/images/new_icon/shanchu.png" width="18" height="18" @click="dele(sItem)" />
                      </td>
                    </tr>
                  </table>
                </div>

              </div>
            </div>

          </div>
        </el-col>
        <el-col :span="14" style="height: 100%">
          <div class="dataList-r">
            <div class="dataList-r-tit">添加互动答题题目</div>
            <div class="search">
              <div class="search-item">
                <div class="search-item-ra">
<!--                  <el-radio-group v-model="chapterType" @input="changeChapterType">-->
<!--                    <el-radio :label="1">选择学习任务</el-radio>-->
<!--                    <el-radio :label="2">选择课程目录</el-radio>-->
<!--                  </el-radio-group>-->
                  <div class="name">
                    章节:
                  </div>
                  <div class="search-item-ra-select">
                    <el-cascader
                        v-if="chapterType == 1"
                        v-model="nodeIdsArr"
                        :options="taskList"
                        :props="taskOptionProps"
                        style="width: 160px"
                        size="small"
                        collapse-tags
                        @change="changeMapNode"
                        clearable
                    ></el-cascader>
                    <el-cascader
                        v-if="chapterType == 2"
                        v-model="mapNodeIds"
                        :options="mapList"
                        :props="mapOptionProps"
                        style="width: 160px"
                        size="small"
                        collapse-tags
                        @change="changeMapNode"
                        clearable
                    ></el-cascader>
                  </div>

                </div>

              </div>
              <div class="search-item">
                <div class="name">
                  难度:
                </div>
                <div class="cont">
                  <el-select
                      v-model="levelIds"
                      multiple
                      collapse-tags
                      style="margin-left: 10px;"
                      class="w90"
                      size="small"
                      placeholder="请选择"
                      @change="search"
                      clearable
                  >
                    <el-option
                        v-for="item in levelList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div class="search-item">
                <div class="name">
                  题型:
                </div>
                <div class="cont">
                  <el-select
                      v-model="typeIds"
                      multiple
                      collapse-tags
                      style="margin-left: 10px;"
                      class="w90"
                      size="small"
                      placeholder="请选择"
                      @change="search"
                      clearable
                  >
                    <el-option
                        v-for="item in typeList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div class="search-item">
                <div class="name">
                  关键词搜索:
                </div>
                <div class="cont">
                  <el-input
                      placeholder="请输入内容"
                      prefix-icon="el-icon-search"
                      style="margin-left: 10px;"
                      v-model="keyword"
                      size="small"
                      @change="search"
                      clearable
                  >
                  </el-input>
                </div>
              </div>
            </div>
            <div class="dataList-cont-list-top">
              <el-button type="primary" size="small" @click="addAll">全部添加</el-button>
              <el-button type="primary" size="small" @click="cancelAll">全部取消</el-button>
              <p>已选择<span>{{catchSelectData.length}}</span>条</p>
            </div>
            <table class="dataList-cont-list-item-table">
              <tr>
                <th><el-checkbox v-model="allChecked" @change="changeAllCheckbox"></el-checkbox></th>
                <th class="table-type">题型</th>
                <th class="th-title">题目</th>
                <th class="table-level">难度</th>
                <th class="data-btns">操作</th>
              </tr>
              <tr v-for="item in dataList" :key="item.id">
                <td><el-checkbox v-model="item.checked" @change="changeCheckbox(item)"></el-checkbox></td>
                <td class="table-type">
                  <p class="data-type" :class="typeBj[item.type]">{{ dataTypes[item.type] }}</p>
                </td>
                <td class="data-tit" @click="showDetail(item)">
                  <div>{{ item.title }}</div>
                </td>
                <td class="table-level">{{ dataLevels[item.level] }}</td>
                <td class="data-btns" >
                  <div class="active" v-if="!item.selectStatus" @click="addData(item)">添加</div>
                  <div v-else @click="deleData(item)">取消添加</div>
                </td>
              </tr>
            </table>
            <div class="page">
              <MyPage :total="total" :current="page" @changePage="changePage" @changePageSize="changePageSize"></MyPage>
            </div>
          </div>
        </el-col>
      </el-row>


    </div>

    <el-drawer
        :visible.sync="dataDetailModal"
        title="题目详情"
        @on-cancel="closeDataDetail"
        footer-hide
        width="600px"
    >
      <div class="drawer-title" slot="title">
        <div class="drawer-title-text">题目详情</div>
      </div>
      <div style="padding: 20px">
        <DataDetail v-if="dataDetailModal" :dataId="curData.id"></DataDetail>
      </div>

    </el-drawer>
<!--    <DeleModal :status="deleModalStatus" txt="确认删除此题吗？" :loading="modalLoading" @cancel="cancelDele" @confirm="confirmDele"></DeleModal>-->
  </div>
</template>

<script>
import DeleModal from '@/components/deleteModal.vue';
import DataDetail from '@/components/dataInfo';
import util from '@/utils/tools.js';
export default {
  name: "interactAdd.vue",
  data(){
    return{
      dataTypeList:[],
      curData:{},
      dataDetailModal:false,
      deleModalStatus:false,
      modalLoading:false,
      total:0,
      page:1,
      pageSize:10,
      mapId:'',
      nodeIds:[],
      levelIds:[],
      typeIds:[],
      dataList:[],
      dataTypes:{},
      dataLevels:{},
      levelList:[],
      typeList:[],
      allChecked:false,
      keyword:'',
      chapterType:1,
      chapterList:[],
      taskList:[],
      mapList:[],
      courseId:'',
      taskOptionProps: {
        value: "node_child_id",
        label: "name",
        multiple: true
      },
      mapOptionProps: {
        value: "node_child_id",
        label: "name",
        children: "children",
        checkStrictly:true,
        multiple: true
      },
      mapNodeIds:[],
      name:'',
      typeBj:{
        1:'bjFD0',
        2:'purple',
        3:'bj3ff',
        4:'bj1e9',
        8:'bj3FF',
        9:'bj9E7',
        10:'bj6e9'
      },
      selectDataList:[],  //真正添加的数据
      catchSelectData:[], //checkbox选中的数据
      saveLoading:false,
      interactId:'',  //互动id
      taskId:'',  //任务id
      nodeIdsArr:[],
    }
  },
  components:{
    DeleModal,
    DataDetail
  },
  created(){
    this.mapId = this.$route.query.mapId || '';
    this.courseId = this.$route.query.courseId || '';
    this.interactId = this.$route.query.interactId || '';
    this.taskId = this.$route.query.taskId || '';
    if(this.interactId){
      this.getDetail();
    }else{
      this.getList();
    }

    this.getTaskDetail();
    this.getMapDetail();
  },
  methods:{
    getDetail(){
      let params = {
        interact_id:this.interactId
      };
      this.api.course.courseInteractDetail(params).then((res)=>{
        this.name = res.info.name;
        this.dataTypeList = res.data_list;
        let arr = [];
        this.dataTypeList.forEach((item)=>{
          this.$set(item,'show',true);
          arr = arr.concat(item.list);
        })
        this.selectDataList = JSON.parse(JSON.stringify(arr));
        this.nodeIdsArr = res.info.map_node_ids ? JSON.parse(res.info.map_node_ids) : [];
        if(this.nodeIdsArr.length){
          this.nodeIds = [];
          this.nodeIdsArr.forEach((item)=>{
            this.nodeIds.push(item[item.length - 1]);
          })
        }

        console.log(this.nodeIds,'this.nodeIds')
        this.levelIds = res.info.data_level_str ? res.info.data_level_str.split(',') : [];
        this.typeIds = res.info.data_type_str ? res.info.data_type_str.split(',') : [];
        this.getList();
      })
    },
    getTaskDetail(){
      let params = {
        task_id:this.taskId
      };
      this.api.course.courseTaskDetail(params).then((res)=>{
        this.taskList = res.task.children;
        // this.chapterList = this.taskList;
      })
    },
    getMapDetail(){
      let params = {
        id:this.mapId,
        is_filter_empty_children:1
      };
      this.api.dataset.mapDetail(params).then((res)=>{
        this.mapList = res.info.tree;
      })
    },
    changeChapterType(){
      console.log(this.nodeIds,'this.nodeIds')
      this.nodeIds = [];
    },
    changeMapNode(data){
      this.nodeIds = [];
      data.forEach((item)=>{
        this.nodeIds.push(item[item.length - 1]);
      })
      this.nodeIdsArr = data;
      this.search();
    },
    showItem(data){
      data.show = !data.show;
    },
    showDetail(data){
      this.curData = data;
      this.dataDetailModal = true;
    },
    closeDataDetail(){
      this.dataDetailModal = false;
    },
    // dele(data){
    //   if(!this.isEdit) return;
    //   this.curData = data;
    //   this.deleModalStatus = true;
    // },
    // cancelDele(){
    //   this.deleModalStatus = false;
    // },
    // confirmDele(){
    //   let params = {
    //     op:'release',
    //     train_id:this.trainId,
    //     data_id:this.curData.id,
    //   };
    //   this.modalLoading = true;
    //   this.api.dataset.datasetTrainDataDel(params).then((res)=>{
    //     this.modalLoading = false;
    //     this.$Message.success('删除成功');
    //     this.deleModalStatus = false;
    //     // this.getList();
    //   }).catch((err)=>{
    //     this.modalLoading = false;
    //   })
    // },
    search(type){
      this.page = 1;
      this.getList();
    },
    nodeSearch(val){
      this.nodeIdsArr = val;
      this.search();
    },
    getList(){
      let params = {
        page:this.page,
        pageSize:this.pageSize,
        map_id:this.mapId,
        node_id:this.nodeIds.join(','),
        level_str:this.levelIds.join(','),
        type_str:this.typeIds.join(','),
        keyword:this.keyword,
      }
      this.loading = true;
      this.api.course.mapDataListV1(params).then((res)=>{
        this.loading = false;
        this.dataList = res.list;
        this.dataTypes = res.data_types;
        this.dataLevels = res.data_levels;
        this.levelList = util.jsonToArr(res.data_levels);
        this.typeList = util.jsonToArr(res.data_types);
        this.total = Number(res.count);
        this.dataList.forEach((item)=>{
          let index = this.catchSelectData.findIndex((sItem)=>{
            return item.id == sItem.id;
          })
          if(index > -1){
            this.$set(item,'checked',true);
          }else{
            this.$set(item,'checked',false);
          }

          let selectIndex = this.selectDataList.findIndex((sItem)=>{
            return item.id == sItem.id;
          })
          if(selectIndex > -1){
            this.$set(item,'selectStatus',true);
          }else{
            this.$set(item,'selectStatus',false);
          }

        })
      }).catch((err)=>{
        this.loading = false;
      })
    },
    changePage(page){
      this.page = page;
      this.allChecked = false;

      this.getList();
    },
    changePageSize(pageSize){
      this.page = 1;
      this.pageSize = pageSize;
      this.getList();
    },
    addData(data){
      this.$set(data,'selectStatus',true);
      this.selectDataList.push(data);
      this.dataTypeList = this.formatDataList(this.selectDataList);
      console.log(this.dataTypeList,'dataTypeListdataTypeList')
    },
    deleData(data){
      this.$set(data,'selectStatus',false);
      let index = this.selectDataList.findIndex((item)=>{
        return item.id == data.id;
      });
      this.$delete(this.selectDataList,index);
      this.dataTypeList = this.formatDataList(this.selectDataList);
      console.log(this.dataTypeList,'dataTypeListdataTypeList')
    },
    formatDataList(data){
      let result = {};
      let arr = [];

      for (let i = 0; i < data.length; i++) {
        let item = data[i];
        let type = item.type;

        if (!result[type]) {
          result[type] = [];
        }

        result[type].push(item);
      }
      for(let name in result){
        arr.push({
          name:this.dataTypes[name],
          id:name,
          list:result[name],
          show:true,
        })
      }
      return arr;
    },
    changeCheckbox(data){
      let arr = this.dataList.filter((item)=>{
        return item.checked;
      })
      if(arr.length == this.dataList.length){
        this.allChecked = true;
      }else{
        this.allChecked = false;
      }
      if(data.checked){
        this.catchSelectData.push(data)
      }else{
        let index = this.catchSelectData.findIndex((item)=>{
          return item.id == data.id;
        })
        if(index > -1){
          this.$delete(this.catchSelectData,index);
        }
      }

    },
    changeAllCheckbox(){
      let status;
      if(this.allChecked){
        status = true;
      }else{
        status = false;
      }
      this.dataList.forEach((item)=>{
        item.checked = status;
        let index = this.catchSelectData.findIndex((sItem)=>{
          return item.id == sItem.id;
        })
        if(status){ //添加
          if(index == -1){
            this.catchSelectData.push(item)
          }
        }else{  //取消
          if(index > -1){
            this.$delete(this.catchSelectData,index);
          }
        }
      })
    },
    addAll(){ //全部添加
      this.catchSelectData.forEach((item)=>{
        let index = this.selectDataList.findIndex((sItem)=>{
          return sItem.id == item.id;
        })
        console.log(index,'dddddd')
        if(index == -1){
          this.selectDataList.push(item);
        }
      })
      this.catchSelectData = [];
      this.allChecked = false;

      this.dataTypeList = this.formatDataList(this.selectDataList);
      this.page = 1;
      this.getList();
    },
    cancelAll(){  //全部取消
      this.catchSelectData.forEach((item)=>{
        let index = this.selectDataList.findIndex((sItem)=>{
          return item.id == sItem.id;
        })
        if(index > -1){
          this.$delete(this.selectDataList,index);
        }
      })
      this.catchSelectData = [];
      this.allChecked = false;

      this.dataTypeList = this.formatDataList(this.selectDataList);
      this.page = 1;
      this.getList();
    },
    dele(data){
      let index = this.selectDataList.findIndex((item)=>{
        return item.id == data.id;
      })
      if(index > -1){
        this.$delete(this.selectDataList,index);
        this.dataTypeList = this.formatDataList(this.selectDataList);
      }
      this.getList();
    },
    cancel(){
      this.$router.go(-1);
    },
    save(){
      if(this.saveLoading) return;
      if(!this.name){
        this.$Message.warning('请输入标题');
        return;
      }
      if(!this.selectDataList.length){
        this.$Message.warning('请选择试题');
        return;
      }
      let dataIds = this.selectDataList.map((item)=>{
        return item.id;
      });
      let params = {
        name:this.name,
        course_id:this.courseId,
        data_ids:dataIds,
        data_level_str:this.levelIds.join(','),
        data_type_str:this.typeIds.join(','),
        task_id:this.taskId,
        map_node_ids:JSON.stringify(this.nodeIdsArr),
      }
      this.saveLoading = true;
      if(this.interactId){
        params.interact_id = this.interactId;
        this.api.course.courseInteractUpdate(params).then((res)=>{
          this.saveLoading = false;
          this.$Message.success('保存成功');
          this.$router.go(-1);
        }).catch((err)=>{
          this.saveLoading = false;
        })
      }else{
        this.api.course.courseInteractCreate(params).then((res)=>{
          this.saveLoading = false;
          this.$Message.success('保存成功');
          this.$router.go(-1);
        }).catch((err)=>{
          this.saveLoading = false;
        })
      }


    }
  }
}
</script>

<style scoped lang="scss">
.interact-add{
  margin:20px;
  //padding-top: 10px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  font-size: 14px;
  height: 100%;
  .interact-top{
    padding: 20px;
    margin-bottom: 20px;
    background: #FFFFFF;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .interact-top-l{
      flex: 1;
      width: 0;
      .interact-top-l-t{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        >span{
          font-size: 14px;
          font-weight: bold;
          color: #333333;
        }
      }
      .interact-top-l-b{
        margin-top: 10px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 12px;
        color: #666666;
        .interact-top-l-b-help{
          margin-left: 10px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          font-size: 12px;
          font-weight: bold;
          color: #4A79FF;
          >img{

          }
        }
      }
    }
    .interact-top-r{
      width: 200px;
      display: flex;
      justify-content: flex-end;
    }
  }
  .interact-cont{
    flex: 1;
    height: 0;
    display: flex;
    justify-content: flex-start;
  }
  .dataList-l{
    margin-right: 10px;
    padding: 20px;
    background: #FFFFFF;
    height: 100%;
    overflow-y: auto;
    .dataList-l-top{
      margin-bottom: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 16px;
      font-weight: bold;
      color: #333333;
      .dataList-l-top-fgx{
        padding: 0 20px;
      }
      .dataList-l-top-num{
        color:#5578F6;
        >span{
          padding: 0 5px;
        }
      }
    }
    .dataList-cont-list{
      font-weight: bold;
      color: #333333;
      .dataList-cont-list-item{
        margin-top: 30px;

        .dataList-cont-list-item-tit{
          display: flex;
          justify-content: flex-start;
          align-items: center;
          font-size: 16px;
          font-weight: bold;
          color: #333333;
          cursor: pointer;
          >p{
            margin-right: 6px;
            width: 4px;
            height: 16px;
            background: #4A79FF;
          }
          >img{
            margin-left: 10px;
          }

        }
        .dataList-cont-list-item-div{
          height:0px;
          transition:height 0.5s;
          overflow: hidden;
        }

      }
    }
  }
  .dataList-cont-list-top{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    >p{
      margin-left: 10px;
      >span{
        padding: 0 5px;
        font-weight: bold;
        color:#5782FF;
      }
    }
  }
  .dataList-cont-list-item-table{
    margin-top: 10px;
    width: 100%;

    >tr{
      width: 100%;
      height: 44px;
      border-bottom: 1px solid #E8EAEC;
      display: flex;
      align-items: center;

      .th-title{
        padding-left: 50px;
        flex: 1;
        width: 0;
      }
      //>th:nth-child(2),>td:nth-child(2){
      //  width: 160px;
      //  text-align: center;
      //}
      //>th:nth-child(3),>td:nth-child(3){
      //  width: 160px;
      //  text-align: center;
      //}
      //>td:first-child{
      //  flex: 1;
      //}
    }
    >tr:first-child{
      height: 40px;
      background: #F8F8F9;
      border: 1px solid #E8EAEC;
      border-radius: 1px;
      text-align: center;
    }
    .data-tit{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex: 1;
      width: 0;
      color: #333333;
      height: 44px;
      >div{
        flex: 1;
        width: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .data-btns{
      width: 100px;
      height: 44px;
      display: flex;
      justify-content: center;
      align-items: center;
      >div{
        margin-right: 10px;
        width: 80px;
        height: 28px;
        background: #EEEEEE;
        border-radius: 4px;
        color: #666666;
        text-align: center;
        line-height: 28px;
        cursor: pointer;
      }
      .active{
        background: #5782FF;
        color:#FFFFFF;
      }
    }
    .data-type{
      margin:0 auto;
      width: 39px;
      height: 26px;
      text-align: center;
      line-height: 26px;

    }
    .table-level{
      width: 80px;
    }
    .table-type{
      width: 110px;
      text-align: center;
    }
  }
  .dataList-r{
    padding: 20px;
    background: #FFFFFF;
    height: 100%;
    overflow-y: auto;
    .dataList-r-tit{
      font-size: 16px;
      font-weight: bold;
      color: #333333;
    }
    .search{
      margin-top: 20px;
      margin-bottom: 20px;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      border-bottom: 1px solid #EEEEEE;
      .search-item{
        margin-bottom: 15px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        font-size: 14px;
        .search-item-ra{
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-right: 30px;
          .search-item-ra-select{
            margin-left: 10px;
          }
        }
        .name{
          padding: 5px 0;
          text-align: right;
        }
        .cont{
          margin-right: 20px;
          flex: 1;
          display: flex;
          justify-content: space-between;

          .values{
            display: flex;
            justify-content: flex-start;
            flex-flow: wrap;
            transition: max-height 0.3s linear;
            overflow: hidden;

            >div{
              padding: 5px 10px;
              //margin: 0 10px 10px 5px;
              cursor: pointer;
            }
            >div.active{
              background-color: #2d8cf0;
              color:#FFFFFF;
              border-radius: 4px;
            }
          }
          .more{
            padding: 5px 0;
            width: 80px;
            text-align: right;
            cursor: pointer;
            .more-icon{
              transition: transform 0.3s linear;
            }
          }
        }
      }
    }


  }
  .w90{
    width: 130px;
  }
  .page{
    margin-top: 20px;
    text-align: right;
  }
}
</style>
