<template>
  <div class="data-cont">
    <div class="tit">
      <Richtxt :dataObj="{val:dataDeatil.name}" :isInline="true" :isDisabled="true"></Richtxt>
      <!--    {{dataDeatil.name}}-->
    </div>
    <div class="options">
      <div v-for="(item, i) in dataDeatil.options" :key="item.id">
        <Radio v-model="item.is_correct == 1" disabled> {{String.fromCharCode(i+65)}}
          <span class="options-item">
          <Richtxt :dataObj="{val:item.name}" :isInline="true" :isDisabled="true"></Richtxt>
        </span>
        </Radio>
      </div>
    </div>
    <div class="correct">
      {{$t('dataset_data_right_key')}}：<span>{{getRightAnswer()}}</span>
    </div>
    <div class="analysis" style="display: inline-block;">
      <Richtxt :dataObj="{val:dataDeatil.explain}" :isInline="true" :isDisabled="true"></Richtxt>
    </div>
  </div>
</template>

<script>
import Richtxt from '@/components/production/richtxt.vue';
import util from '@/utils/tools.js';
export default {
  name: "cont.vue",
  data(){
    return {
      optionNameList:[this.$t('trainingcamp_train_correction_option_a'),this.$t('trainingcamp_train_correction_option_b'),this.$t('trainingcamp_train_correction_option_c'),this.$t('trainingcamp_train_correction_option_d'),this.$t('trainingcamp_train_correction_option_e'),this.$t('trainingcamp_train_correction_option_f'),this.$t('trainingcamp_train_correction_option_g')],
      dataDeatil:{},
    }
  },
  components:{
    Richtxt
  },
  props:{
    dataId:{
      type:String,
      default:''
    },
  },
  created(){
    this.getDetail();
  },
  methods:{
    getDetail(){
      let params = {
        id:this.dataId
      };
      this.loading = true;
      this.api.dataset.dataDetail(params).then((res)=>{
        this.loading = false;
        this.dataDeatil = res.info;
        // this.examDeatil.batch.name = util.decrypt(this.examDeatil.batch.name);

        this.dataDeatil.created_at = util.timeFormatter(
            new Date(+res.info.created_at * 1000),
            'yyyy-MM-dd'
        );
        this.dataDeatil.updated_at = util.timeFormatter(
            new Date(+res.info.updated_at * 1000),
            'yyyy-MM-dd'
        );
      }).catch((err)=>{
        this.loading = false;
      })
    },
    getRightAnswer(){
      var rightanswer = '';
      if(this.dataDeatil.options){
        this.dataDeatil.options.forEach((item, i)=>{
          if (item.is_correct == '1'){
            var codestr = (i + 65);
            rightanswer +=  String.fromCharCode(codestr); //str.charCodeAt()
          }
        });
      }
      return rightanswer;
    },

  }
}
</script>

<style scoped lang="scss">
.data-cont{
  margin-top: 20px;
  font-size: 14px;

  .tit{
    font-size: 16px;
    display: inline-block;
  }
  .options{
    margin-top: 10px;
    margin-left: 10px;
    >div{
      line-height: 40px;
    }
    .options-item{
      display: inline-block;
    }
  }
  .correct{
    margin-top: 10px;
    margin-bottom: 10px;
    >span{
      color: #2d8cf0;
    }
  }
}
</style>
